import { formatDistanceToNow, lightFormat, toDate } from 'date-fns'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function RequestTime({ time }: { time: number }): JSX.Element {
  return (
      <OverlayTrigger overlay={<Tooltip>{lightFormat(toDate(time * 1000), 'yyyy-MM-dd HH:mm:ss')}</Tooltip>}>
          <span>{formatDistanceToNow(time * 1000, { addSuffix: true })}</span>
      </OverlayTrigger>
  )
}

export default RequestTime
