import React, {useState} from "react";
import {useAccount, useBalance, useConnect} from "wagmi";
import { trimName } from "../../utils/utils";
import { useWeb3Modal } from "@web3modal/react";
import { ButtonConnect, ButtonConnected, Address, Balance } from "./styled";
import {Button, Modal} from 'react-bootstrap';
import { SlClose } from "react-icons/sl"
import styled from "styled-components"


export const Close = styled(SlClose)`
  position: absolute;
  right: 30px;
  font-size: 50px;
  cursor: pointer;
`

function WalletButton() {
  const { open } = useWeb3Modal();
  const { address, isConnected }: any = useAccount();
  const [show, setShow] = useState(false);
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //@ts-ignore
  const { data } = useBalance({ address });
  return (
    <>
      {isConnected ? (
        <ButtonConnected onClick={() => open()}>
          <Balance>
            {parseFloat(data?.formatted || "0").toFixed(4)} VIC
          </Balance>
          <Address>{trimName(address, 6, 6)}</Address>
        </ButtonConnected>
      ) : (

        <div>
          <ButtonConnect className="connect-button" onClick={handleShow}>Connect Wallet</ButtonConnect>

          <Modal
            show={show}
            onHide={handleClose}
            className="connect-wallet-modal"
          >
            <Modal.Header>
              <Modal.Title>Connect Wallet</Modal.Title>
              <Close onClick={handleClose} size={20} />
            </Modal.Header>
            <Modal.Body>
              {connectors.map((connector) => (
                <button
                  className="btn connect-button green-button"
                  disabled={!connector.ready}
                  key={connector.id}
                  onClick={() => connect({ connector })}
                >
                  {connector.name}
                  {!connector.ready && ' (unsupported)'}
                  {isLoading &&
                    connector.id === pendingConnector?.id &&
                    ' (connecting)'}
                </button>
              ))}

              {error && <div>{error.message}</div>}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}
export default WalletButton;
