export const ReadyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.113 1.333h5.78c2.26 0 3.774 1.587 3.774 3.947v5.447c0 2.353-1.514 3.94-3.774 3.94h-5.78c-2.26 0-3.78-1.587-3.78-3.94V5.28c0-2.36 1.52-3.947 3.78-3.947zm2.507 8.66l3.167-3.166a.587.587 0 000-.827.587.587 0 00-.827 0L7.207 8.754 6.04 7.587a.587.587 0 00-.827 0 .587.587 0 000 .827L6.8 9.994a.57.57 0 00.407.166.57.57 0 00.413-.167z"
      fill="#53BA95"
    ></path>
  </svg>
);
export const PendingIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.113 1.333h5.78c2.26 0 3.774 1.587 3.774 3.946v5.447c0 2.354-1.514 3.94-3.774 3.94h-5.78c-2.26 0-3.78-1.586-3.78-3.94V5.28c0-2.36 1.52-3.946 3.78-3.946zm5.274 9.207a.495.495 0 00.426-.246.496.496 0 00-.173-.687L8.267 8.194V5.113a.5.5 0 10-1 0V8.48c0 .173.093.34.246.427l2.614 1.56a.47.47 0 00.26.073z"
      fill="#EC8600"
    ></path>
  </svg>
);
