import {
  useAccount,
  useNetwork,
} from "wagmi"
import { switchNetwork } from "@wagmi/core"
import { DEFAULT_CHAIN_ID } from "../../contants"
import Alert from "react-bootstrap/Alert"
import React from "react"

export default function WrongNetwork() {
  const { chain } = useNetwork();
  const { isConnected }: any = useAccount();
  const handleSwitchNetwork = () => {
    switchNetwork({ chainId: DEFAULT_CHAIN_ID });
  };
  return (
    <>
      {isConnected && chain?.unsupported && (
        <div>

          <Alert variant="danger">
            <p>Unsupported chain. Please switch to Viction network in your wallet.&nbsp;
              <Alert.Link rel="noopener" href='#' onClick={handleSwitchNetwork} style={{color: 'rgb(0, 197, 154)'}}>Click here to switch</Alert.Link>.

            </p>
          </Alert>
        </div>
      )}
    </>
  );
}
