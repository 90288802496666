import styled from 'styled-components'
import {
  trimName,
} from "../../../utils/utils"
import {lightTheme} from "../../../themes"
import {useNetwork} from "wagmi";
import config from "../../../config.json";
import {DEFAULT_CHAIN_ID} from "../../../contants";


const Link = styled.a`
  font-size: 0.875rem;
  color: ${lightTheme.greenColor};
  text-decoration: none;
`

interface RequestLinkProps {
  name: string
  hash: string
}

function RequestLink(props: RequestLinkProps): JSX.Element {
  const { chain } = useNetwork();
  const chainId: string = !chain?.unsupported
      ? chain?.id.toString() || DEFAULT_CHAIN_ID.toString()
      : DEFAULT_CHAIN_ID.toString();
  const urlBaseScanDefault =
      //@ts-ignore
      config[chainId]?.configChain.blockExplorers.default.url;
  const { name, hash } = props
  if (hash === undefined || hash === 'System' || hash === 'VicPool_v1') {
    return (
      <Link>{hash === undefined ? 'System' : hash }</Link>
    )
  }
  return (
    <Link href={
      chain
          ? chain?.blockExplorers?.default.url + "/" + name + "/" + hash
          : urlBaseScanDefault + "/" + name + "/" + hash
    } target="__blank" rel="noopener nofollow noreferrer">
      {trimName(hash, 6, 4, 32)}
    </Link>
  )

}

export default RequestLink
