import { useCallback } from "react"
import axios from "axios"
import config from "../../config.json"

export const useListStake = (
  address: string,
  chainId: number | undefined
): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    let result: any[] = [];

    try {
      const response = await axios.get(
        `${
          //@ts-ignore
          config[chainId].api
        }/migrate/my-pool/${address}`
      );
      if (response.status === 200 && response.data) {
        result = response.data.pools;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  }, [chainId, address]);
};

export const useMigrateData = (
  txHash: string,
  chainId: number | undefined
): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    let result: any = {}

    try {
      const response = await axios.get(
        `${
          //@ts-ignore
          config[chainId].api
        }/migrate/${txHash}`
      )
      if (response.status === 200 && response.data) {
        result = response.data
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [txHash])
}

export const useExplorer = (
  address: string,
  chainId: number,
  page: number,
  limit: number
): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    let result: any = {}
    const fillterUser = address ? `/${address}` : "/"
    try {
      // @ts-ignore
      const url = config[chainId].api + `/transaction` + fillterUser + `?page=${page+1}&limit=${limit}`
      const response = await axios.get(url)
      if (response.status === 200 && response.data) {
        result = response.data
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [address])
};
