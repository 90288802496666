
import { FaGithub, FaTelegram, FaTwitter } from "react-icons/fa";
import Logo from '../../asset/vicpool2.png'


import React from 'react';
import {BsFileLockFill} from "react-icons/bs"
import {MdPrivacyTip} from "react-icons/md"
import {IoIosHelpCircle} from "react-icons/io"
import styled from "styled-components";


export const IconList = styled.div`
  a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 40px;
    
    :hover {
      color: rgb(0, 197, 154);
    }
  }
`;
export default function DefaultFooter() {
  return (
    <footer>
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col-md-4 mt-md-0 mt-3">
            <img src={Logo} height={50} alt='VicPool'/>
          </div>
          <div className="col-md-4 mb-md-0 mb-3" style={{lineHeight: '60px'}}>
            <a className='footer-link' href='https://docs.vicpool.fi/'><IoIosHelpCircle/> Documents</a>|
            <a className='footer-link' href='https://docs.vicpool.fi/terms-of-use'><BsFileLockFill/> Terms</a>|
            <a className='footer-link' href='https://docs.vicpool.fi/privacy-notice'><MdPrivacyTip/> Privacy</a>
          </div>

          <IconList className="col-md-4 mb-md-0 mb-3">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="https://t.me/vic_pool">
                  <FaTelegram/>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://twitter.com/vicpool_fi">
                  <FaTwitter/>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://github.com/vic-pool">
                  <FaGithub/>
                </a>
              </li>
            </ul>
          </IconList>
        </div>
      </div>

    </footer>
  )
}
