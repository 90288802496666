import styled from 'styled-components'

// Table
export const StyledSpan = styled.span`
  font-size: 0.875rem;
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
`
export const CollapseWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #23262f;
  padding-top: 0.5rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 2;
  color: #777e90;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    color: #777e90;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > span {
      margin-right: 0.25rem;

      + span {
        margin: 0;
      }
    }
  }

  @media (min-width: 48rem) {
    flex-direction: row;
    align-items: center;

    > div {
      margin-right: 0.5rem;
    }
  }
`
