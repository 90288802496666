import {Action} from '../../../type/Transaction'
import {CollapseWrap, Row} from '../Styled'
import {formatEther, formatString} from "../../../utils/utils"
import React from "react"
import RequestLink from "./RequestLink"

function ActionDetail({actions}: { actions: Action[] }): JSX.Element {

  return (
    <CollapseWrap>
      {actions.map((action, index) => (
        <div key={index}>
          {
            action.action === 'Staked' ?
              (<Row className='d-inline-block'>
                Staked {formatString(formatEther(action.vicAmount).toString())} VIC to&nbsp;
                <RequestLink
                  name='address'
                  hash={action.validator}
                />
              </Row>)
            : action.action === 'Unstaked' ?
              (<Row className='d-inline-block'>
                Unstaked {formatString(formatEther(action.vicAmount).toString())} VIC from&nbsp;
                <RequestLink
                  name='address'
                  hash={action.validator}
                />
              </Row>)
            : action.action === 'Withdrawal' ?
              (<Row className='d-inline-block'>
                Withdrawal {formatString(formatEther(action.vicAmount).toString())} VIC&nbsp;
              </Row>)
            : action.action === 'Migrate' ?
              (<Row className='d-inline-block'>
                Migrate {formatString(formatEther(action.vicAmount).toString())} VIC&nbsp;
                  to {formatString(formatEther(action.sAmount).toString())} sVIC at&nbsp;
                <RequestLink
                  name='address'
                  hash={action.validator}
                />
              </Row>)
            : (<span></span>)

          }
        </div>
      ))}
    </CollapseWrap>
  )
}

export default ActionDetail
