import { useCallback } from "react"
import axios from "axios"
import config from "../../config.json"

export const useRequestWithdrawal = (
  stAmount: string,
  address: string,
  chainId: string
): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    let result = {
      amountsForUnstake: [],
      normalAddress: [],
      normalAmount: [],
      poolAddress: [],
      poolAmount: [],
      expiryTime: 0,
      rs: [],
      v: "",
    }

    try {
      const response = await axios.post(
        `${
          //@ts-ignore
          config[chainId].api
        }/stake/request-unstake`,
        { stAmount: Number(stAmount), sender: address, recipient: address }
      )
      if (response.status === 200 && response.data) {
        result = response.data
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [stAmount, chainId, address])
}
