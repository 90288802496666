import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {device, deviceMax, lightTheme} from "../../themes";

export const WrapHeader = styled.header`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 2200px) {
    padding: 1rem 40rem;
  }
  @media (max-width: 2199px) {
    padding: 1rem 20rem;
  }
  @media (max-width: 1920px) {
    padding: 1rem 10rem;
  }
  @media (max-width: 1366px) {
    padding: 1rem 5rem;
  }
  @media (max-width: 1160px) {
    padding: 1rem 1rem;
  }
  @media (max-width: 1050px) {
    padding: 1rem 0;
  }

`;
export const LogoStyled = styled.img`
  display: none;

  @media (min-width: 992px) {
    display: flex;
  }
`
export const DivStyled = styled.div`
  display: flex;
  gap: 1.3rem;
  align-items: center;
  @media (${deviceMax.tablet}) {
    display: none;
  }
`;
export const NavLinkStyled = styled(NavLink)`
  color: ${(props) => props.theme.PrimaryColor};
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6em;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryColor2)};

  &:hover {
    color: ${lightTheme.greenColor};
  }
`;

export const NavLink2 = styled.a`
  color: ${(props) => props.theme.PrimaryColor};
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6em;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryColor2)};
  &:hover {
    color: ${lightTheme.greenColor};
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const HeaderMobile = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: white;
  @media (${device.tablet}) {
    display: none;
  }
`;
export const MenuToggle = styled.button`
  background: transparent;
  border: none;
  color: white;

  @media (min-width: 992px) {
    display: none;
  }
`;

