import React, { useEffect, useState } from "react";
import {useSearchParams} from 'react-router-dom'
import { useAccount, useNetwork } from "wagmi";
import { useListStake } from "../../components/hooks/useApi";
import {formatString, formatEther, formatCurrencyString, ethToWei} from "../../utils/utils";
import SimpleLogo from '../../asset/vicpool.png'
import {
  WrapText, Button as NewBtn,
  Title,
  SecondTitle, PoolName, LinkStyled, YouStake, Box3, PoolLogo, PoolInfo, TransferMigrateBtn
} from "../Stake/styled";
import { Main } from "./styled";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import Modal from "../../components/Modal";
//hardcode
import dataAbi from "../../abi/88/abi.json";
import TomoPoolAbi from "../../abi/88/TomoPool.json";
import { readContract } from "@wagmi/core";
import { lightTheme } from "../../themes";
import {DEFAULT_CHAIN_ID} from "../../contants";
import config from "../../config.json";
import axios from "axios"
import Alert from "react-bootstrap/Alert";
import WrongNetwork from "../../components/WrongNetwork";
export default function Migrate() {
  let { address }: any = useAccount();
  const [queryParameters] = useSearchParams()
  if (queryParameters.has('staker')) {
    address = queryParameters.get('staker')
  }
  const { chain } = useNetwork();

  const chainId: number = !chain?.unsupported
      ? chain?.id || DEFAULT_CHAIN_ID
      : DEFAULT_CHAIN_ID;

  const listStake = useListStake(address, chainId);
  const urlBaseScanDefault =
    //@ts-ignore
    config[chainId]?.configChain.blockExplorers.default.url;
  const [listStakeUser, setListStakeUser] = useState<
    { capacity: number; poolContract: string; poolName: string, transferAmount: string, transferHash: string }[]
  >([]);

  const [showMigrateModal, setShowMigrateModal] = useState(false);

  const [showClaimModel, setShowClaimModel] = useState(false);

  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [stAmountOut, setStAmountOut] = useState('0');
  const [vicAmount, setVicAmount] = useState('0');

  async function getStakeData() {
    const stakeData = await listStake()
    setListStakeUser(stakeData)
  }

  useEffect(() => {
      getStakeData()
  }, [address])

  const handleMigrate = async (pool: any) => {
    setVicAmount(pool.capacity)
    setShowMigrateModal(true);
    try {
      const { request } = await prepareWriteContract({
        address: `0x${pool.poolContract.replace("0x", "")}`,
        //@ts-ignore
        abi: TomoPoolAbi,
        functionName: "transferStake",
        //@ts-ignore
        args: [`0x${dataAbi.address.replace("0x", "")}`, String(pool.capacityString)],
      });
      const { hash } = await writeContract(request);
      setTxHash(hash);
      const waitForTransactionData = await waitForTransaction({
        confirmations: 5, hash,
      });
      if (waitForTransactionData.status) {
        try {
          const response = await axios.post(
            `${
              //@ts-ignore
              config[chainId].api
            }/migrate/submit/transferStake`,
            { txHash: hash }
          );
          if (response.status === 200 && response.data) {
            console.log("submit transfer stake response", response.data);
          }
        } catch (error: any) {
          console.error("submit transfer stake error", error);
        }

        setSuccessMessage(`You have successfully migrate your stake ${formatString(pool.capacity.toString())} VIC 
        from ${pool.poolName} to VicPool v2. Please wait a few seconds before claim sVIC`)
        setSuccess(true)
        setShowMigrateModal(false)

        await getStakeData()
      }
    } catch (e: any) {
      console.warn("handleMigrate fail ", e);
      setFail(true)
      setShowMigrateModal(false)
    }
  };

  const handleClaim = async (pool: any) => {
    const amount = await readContract({
      address: `0x${dataAbi.address.replace("0x", "")}`,
      //@ts-ignore
      abi: dataAbi.abi,
      functionName: "getSVicAmount",
      args: [pool.transferAmount],
    });
    setStAmountOut(String(amount))
    setVicAmount(String(pool.transferAmount))

    try {
      setShowClaimModel(true);
      let data: any = {};

      try {
        const response = await axios.get(
          `${
            //@ts-ignore
            config[chainId].api
          }/migrate/${pool.transferHash}`
        );
        if (response.status === 200 && response.data) {
          data = response.data;
        }
      } catch (error: any) {
        console.error(error);
      }
      const { request } = await prepareWriteContract({
        address: `0x${dataAbi.address.replace("0x", "")}`,
        abi: dataAbi.abi,
        functionName: "migrate2router",
        args: [
          data.txHash,
          data.recipient,
          data.vicAmount,
          data.poolAddress,
          data.expiryTime,
          data.rs,
          data.v,
        ],
      });
      const { hash } = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash,
      });
      if (waitForTransactionData.status) {
        setShowClaimModel(false);
        setShowMigrateModal(false);
        setTxHash(hash)

        try {
          const response = await axios.post(
            `${
              //@ts-ignore
              config[chainId].api
            }/migrate/submit/migrate`,
            { txHash: hash }
          );
          if (response.status === 200 && response.data) {
            console.log("submit migrate respone", response.data);
          }
        } catch (error: any) {
          console.error("submit migrate error", error);
        }

        setSuccessMessage(`You have successfully migrated ${formatString(formatEther(pool.transferAmount).toString())} VIC to VicPool v2. 
        You got ${formatString(formatEther(pool.transferAmount).toString())} sVIC`)
        setSuccess(true)
        await getStakeData()
      }
    } catch (e: any) {
      console.warn("migrate fail ", e);
      setFail(true)
      setShowMigrateModal(false)
      setShowClaimModel(false)
    }
  };
  return (
    <Main>
      <WrapText>
        <Title>Migrate</Title>
        <SecondTitle>Migrate your staked to new VicPool version</SecondTitle>
      </WrapText>
      <WrongNetwork />

      <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        <p>
          Congratulations! {successMessage}.
          More <Alert.Link rel="noopener" href={
          chain
            ? chain?.blockExplorers?.default.url + "/tx/" + txHash
            : urlBaseScanDefault + "/tx/" + txHash
        }>transaction detail</Alert.Link>.
        </p>
      </Alert>
      <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
        <Alert.Heading>Failed!</Alert.Heading>
        <p>
          Ops! There are something wrong. Please try again later
        </p>
      </Alert>
      <div>
        {listStakeUser.length === 0 ? (
          <div style={{ textAlign: "center" }}>No data stake</div>
        ) : (
          listStakeUser.filter(pool => (pool.capacity !== 0 || pool.transferAmount !== '0')).map((pool, i) => (
            <Box3
              key={i}
            >
              <PoolLogo className='expert-margin more-margin'>
                <img src={SimpleLogo} height={60} alt='Pool Logo'/>
              </PoolLogo>
              <PoolInfo className='expert-margin'>
                <PoolName><LinkStyled
                  color={lightTheme.greenBackgroundColor}
                  href={
                    chain
                      ? chain?.blockExplorers?.default.url + "/address/" + pool.poolContract
                      : urlBaseScanDefault + "/address/" + pool.poolContract
                  }
                  target="_blank"
                >
                  {pool.poolName}
                </LinkStyled>
                </PoolName>
                {(() => {
                  if (pool.capacity !== 0) {
                    return (
                      <YouStake>
                        Staked:{" "}
                        {formatCurrencyString(pool.capacity, 2)} VIC
                      </YouStake>
                    )
                  }
                })()}
                {(() => {
                  if (pool.transferAmount !== '0') {
                    return (
                      <YouStake>
                        Transferred:{" "}
                        {formatCurrencyString(
                          parseFloat(formatEther(pool.transferAmount).toString()), 2)} VIC
                      </YouStake>
                    )
                  }
                })()}
              </PoolInfo>
              <TransferMigrateBtn>
                {(() => {
                  if (pool.capacity !== 0) {
                    return (
                      <NewBtn onClick={() => {
                        setSuccess(false);
                        setFail(false);
                        handleMigrate(pool)}
                      }>
                        Migrate
                      </NewBtn>
                    )
                  }
                })()}
                {(() => {
                  if (pool.transferAmount !== '0') {
                    return (
                      <NewBtn onClick={() => {
                        setSuccess(false);
                        setFail(false);
                        handleClaim(pool)}
                      }>
                        Claim
                      </NewBtn>
                    )
                  }
                })()}
              </TransferMigrateBtn>
            </Box3>
          ))
        )}
      </div>
      <Modal
        show={showMigrateModal}
        onRequestClose={() => setShowMigrateModal(false)}
        error={false}
        isNoLoading={false}
      >
        <Title fontSize="20px">
          You are migrating {formatString(vicAmount.toString())} VIC at VicPool v1 to VicPool Liquid Staking v2.
        </Title>
        <SecondTitle fontSize="12px">
          Confirm this transaction in your wallet
        </SecondTitle>
      </Modal>
      <Modal
        show={showClaimModel}
        onRequestClose={() => setShowClaimModel(false)}
        error={false}
        isNoLoading={false}
      >
        <Title fontSize="20px">You are claiming now</Title>
        <SecondTitle fontSize="14px">
          Migrated {formatString(vicAmount.toString())} VIC.
          You will receive {formatString(formatEther(stAmountOut).toString())} sVIC
        </SecondTitle>

        <SecondTitle fontSize="12px">
          Confirm this transaction in your wallet
        </SecondTitle>
      </Modal>
    </Main>
  );
}
