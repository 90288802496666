import React, { useEffect, useState } from "react";
import {
  Main, Box, Input, WrapText, WrapDetail, Detail,
  SpanStyled, Title, SecondTitle, Box2, TitleBox2, BalanceBox2,
  Line, Row, LinkStyled, Form, MaxButton, FullButton,
} from "./styled";
import { lightTheme } from "../../themes";
import { useWeb3Modal } from "@web3modal/react";
import {
  useAccount, useBalance, useContractReads, useNetwork, erc20ABI,
} from "wagmi";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import Alert from 'react-bootstrap/Alert'

import dataAbi from "../../abi/88/abi.json";
import {
  formatCurrencyString,
  formatEther,
  formatString,
  parseEther,
} from "../../utils/utils";
import config from "../../config.json";
import { DEFAULT_CHAIN_ID, MIN_STAKE, TX_FEE_STAKE } from "../../contants";
import { useCoinPrice } from "../../components/hooks/useCoinPrice";
import { useRequestStake } from "../../components/hooks/useRequestStake";

import { Tooltip } from "react-tooltip";
import { FaRegQuestionCircle } from "react-icons/fa";

import Modal from "../../components/Modal";
import WrongNetwork from "../../components/WrongNetwork";
import axios from 'axios'
export default function Stake() {
  const { open } = useWeb3Modal();
  const { address, isConnected }: any = useAccount();
  const { data: balance } = useBalance({ address, watch: true });
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState("");
  const [coinPrice, setCoinPrice] = useState(0);
  const [dataContract, setDataContract] = useState<any>([]);
  const [disable, setDisable] = useState(true);
  const [apy, setApy] = useState(0);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');

  const { chain } = useNetwork();
  const getPrice = useCoinPrice();

  const chainId: string = !chain?.unsupported
    ? chain?.id.toString() || DEFAULT_CHAIN_ID.toString()
    : DEFAULT_CHAIN_ID.toString(); //@ts-ignore
  const sVicAddress = config[chainId].sVicAddress;
  const urlBaseScanDefault =
    //@ts-ignore
    config[chainId]?.configChain.blockExplorers.default.url;
  const nameScanDefault =
    //@ts-ignore
    config[chainId]?.configChain.blockExplorers.default.name;

  const getRequestStake = useRequestStake(value, address, chainId);

  const { data: dataContractHook } = useContractReads({
    contracts: [
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "getSVicAmount",
        args: ['1000000000000000000'],
      },
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "getSVicAmount",
        args: [parseEther(value === "" ? "0" : value)],
      },
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "totalStaked",
      },
      {
        address: `0x${sVicAddress.replace("0x", "")}`,
        //@ts-ignore
        abi: erc20ABI,
        functionName: "balanceOf",
        args: [address],
      },
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "daoFee",
      },
      {
        address: `0x${sVicAddress.replace("0x", "")}`,
        //@ts-ignore
        abi: erc20ABI,
        functionName: "totalSupply",
      },
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "pendingUnstakeForRebalance",
      },
    ],
    //@ts-ignore
    watch: true,
  });
  useEffect(() => {
    if (dataContractHook) setDataContract(dataContractHook);
  }, [dataContractHook]);

  useEffect(() => {
    getPrice().then((price) => setCoinPrice(price));
  }, []);

  useEffect(() => {
    setValue("");
    setDisable(true);
    getApy()
  }, [address, chain]);

  const handleSubmit = async () => {
    setShowModal(true);
    try {
      const {
        normalAddress,
        normalAmount,
        poolAddress,
        poolAmount,
        expiryTime,
        rs,
        v,
      } = await getRequestStake();

      const { request } = await prepareWriteContract({
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "stake",
        //@ts-ignore
        args: [
          address,
          normalAddress,
          normalAmount,
          poolAddress,
          poolAmount,
          expiryTime,
          rs,
          v,
        ],
        //@ts-ignore
        value: parseEther(value),
      });
      const { hash } = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash,
      });
      if (waitForTransactionData.status) {
        setShowModal(false);
        setTxHash(hash);
        setSuccess(true)
      }
    } catch (e: any) {
      console.error(e);
      setFail(true)
      setShowModal(false)
    }
  }

  async function getApy() {
    let data = {
      lastDayReward: '0',
      totalStake: "1",
      apy: 0
    }

    try {
      // @ts-ignore
      const url = config[chainId].api + `/stats/apy`
      const response = await axios.get(url);
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }


    if (data.apy > 0) {
      setApy(data.apy);
    }
    else setApy(0);
  }
  const handleMax = () => {
    const balanceMinusFee =
      parseFloat(balance?.formatted || "0") - TX_FEE_STAKE > 0
        ? parseFloat(balance?.formatted || "0") - TX_FEE_STAKE
        : 0;
    onChangeValue(balanceMinusFee.toString());
  };
  const onChangeValue = (text: string) => {
    setValue(text);
    text.length > 0 &&
    parseFloat(text) >= MIN_STAKE &&
    parseFloat(balance?.formatted || "0") > parseFloat(text)
      ? setDisable(false)
      : setDisable(true);
  };

  return (
    <Main>
      <WrapText>
        <Title>Stake VIC</Title>
        <SecondTitle>Stake VIC and receive sVIC while staking</SecondTitle>
      </WrapText>
      <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        <p>
          Congratulations! You have successfully staked {value} VIC.
          More <Alert.Link rel="noopener" href={
          chain
            ? chain?.blockExplorers?.default.url + "/tx/" + txHash
            : urlBaseScanDefault + "/tx/" + txHash
        }>transaction detail</Alert.Link>.
        </p>
      </Alert>
      <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
        <Alert.Heading>Failed!</Alert.Heading>
        <p>
          Ops! There are something wrong. Please try again later
        </p>
      </Alert>
      {isConnected && !chain?.unsupported && (
        <Box2>
          <TitleBox2>Available to stake</TitleBox2>
          <BalanceBox2 paddingBottom="1rem">
            {" "}
            {formatCurrencyString(parseFloat(balance?.formatted || "0"), 3)} VIC
          </BalanceBox2>
          <Line />
          <Row>
            <div>
              <TitleBox2>sVIC balance</TitleBox2>
              <BalanceBox2 paddingBottom="1rem">
                {" "}
                {dataContract.length > 0 && !dataContract[3]?.error
                  ? formatCurrencyString(parseFloat(
                      formatEther(dataContract[3]?.result).toString()
                    ), 3)
                  : "0"}{" "}
                sVIC
              </BalanceBox2>
            </div>
            <div>
              <TitleBox2>APR</TitleBox2>
              <BalanceBox2 color={lightTheme.greenColor} paddingBottom="1rem">
                {apy} %
              </BalanceBox2>
            </div>
          </Row>
        </Box2>
      )}
      <WrongNetwork />
      <Box>
        <Form disable={disable} value={value}>
          <Input
            placeholder="VIC amount"
            onChange={(e) => onChangeValue(e.target.value)}
            value={value}
            type={"number"}
            min={0}
            onKeyPress={(e) => {
              if (e.code === "Minus") {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              //@ts-ignore
              const clipboardData = e.clipboardData || window.clipboardData;
              const pastedData = parseFloat(clipboardData.getData("text"));

              if (pastedData < 0) {
                e.preventDefault();
              }
            }}
          ></Input>
          <MaxButton onClick={() => handleMax()}>
            <span>Max</span>
          </MaxButton>
        </Form>

        {isConnected ? (
          <FullButton onClick={() => handleSubmit()} disabled={disable}>
            {parseFloat(value) < MIN_STAKE
              ? `Min stake is ${MIN_STAKE}`
              : parseFloat(balance?.formatted || "0") < parseFloat(value)
              ? "Insufficient VIC balance"
              : value.length === 0
              ? "Enter an amount"
              : "STAKE NOW"}
          </FullButton>
        ) : (
          <FullButton onClick={() => open()}>Connect Wallet</FullButton>
        )}

        <WrapDetail>
          <Detail>
            <SpanStyled>You will receive</SpanStyled>
            <SpanStyled>
              {dataContract.length > 0 && !dataContract[1]?.error
                ? formatCurrencyString(parseFloat(formatEther(dataContract[1]?.result).toString()), 3)
                : "0"}{" "}
              sVIC
            </SpanStyled>
          </Detail>
          <Detail>
            <SpanStyled>Exchange rate</SpanStyled>
            <SpanStyled textAlign="right">
              1 VIC ={" "}
              {dataContract.length > 0 && !dataContract[0]?.error
                ? formatString(formatEther(dataContract[0]?.result).toString())
                : "???"}{" "}
              sVIC
            </SpanStyled>
          </Detail>
          {/* <Detail>
            <SpanStyled>Max transaction cost</SpanStyled>
            <SpanStyled>???$</SpanStyled>
          </Detail> */}
          <Detail>
            <SpanStyled>
              Reward fee{" "}
              <FaRegQuestionCircle
                style={{ marginLeft: 5 }}
                data-tooltip-id="annual-percentage-rate"
                data-tooltip-content={
                  "Please note: this fee applies to staking rewards only, and is NOT taken from your staked amount."
                }
              />
              <Tooltip id="annual-percentage-rate" />
            </SpanStyled>
            <SpanStyled>
              {dataContract.length > 0 && !dataContract[4]?.error
                ? parseFloat(dataContract[4]?.result) / 100
                : "???"}
              %
            </SpanStyled>
          </Detail>
        </WrapDetail>
      </Box>
      <Detail marginHoz="1rem">
        <span>Pool statistics</span>
        <LinkStyled
          color={lightTheme.greenBackgroundColor}
          href={
            chain
              ? chain?.blockExplorers?.default.url + "/address/" + sVicAddress
              : urlBaseScanDefault + "/address/" + sVicAddress
          }
          target="_blank"
        >
          View on{" "}
          {chain?.blockExplorers?.default.name
            ? chain?.blockExplorers?.default.name
            : nameScanDefault}
        </LinkStyled>
      </Detail>
      <Box>
        <WrapDetail>
          <Detail>
            <SpanStyled>
              Annual percentage rate{" "}
              <FaRegQuestionCircle
                style={{ marginLeft: 5 }}
                data-tooltip-id="annual-percentage-rate"
                data-tooltip-content={
                  "Moving average of APR for 7 days period."
                }
              />
              <Tooltip id="annual-percentage-rate" />
            </SpanStyled>
            <SpanStyled color={lightTheme.greenColor}>{apy} %</SpanStyled>
          </Detail>
          <Detail>
            <SpanStyled>Total staked</SpanStyled>
            <SpanStyled textAlign="right">
              {dataContract.length > 0 && !dataContract[2]?.error
                ? formatCurrencyString(parseFloat(formatEther(dataContract[2]?.result).toString()), 1 )
                : "0"}{' VIC'}
            </SpanStyled>
          </Detail>
          <>
            {dataContract.length > 0 && !dataContract[6]?.error && String(dataContract[6].result) !== '0' ? (
                <Detail>
                  <SpanStyled>Waiting rebalance</SpanStyled>
                  <SpanStyled textAlign="right">
                    {formatCurrencyString(parseFloat(formatEther(dataContract[6]?.result).toString()), 0)}
                    {' VIC'}
                  </SpanStyled>
                </Detail>
            ) : ('')}
          </>
          <Detail>
            <SpanStyled>sVIC market cap</SpanStyled>
            <SpanStyled>
              $
              {dataContract.length > 0 &&
              !dataContract[5]?.error &&
              !dataContract[0]?.error
                ? formatCurrencyString(parseFloat(
                    (
                      ((BigInt(dataContract[5]?.result) /
                        BigInt(dataContract[0]?.result)) *
                        BigInt(
                          Number(Number(coinPrice.toFixed(2)) * 100).toFixed(0)
                        )) /
                      BigInt(100)
                    ).toString()
                  ), 0)
                : "0"}
            </SpanStyled>
          </Detail>
        </WrapDetail>
      </Box>
      <Modal
        show={showModal}
        onRequestClose={() => setShowModal(false)}
        error={false}
      >
        <Title fontSize="20px">You are now staking {value} VIC</Title>
        <SecondTitle fontSize="14px">
          Staking {value} VIC. You will receive{" "}
          {dataContract.length > 0 && !dataContract[1]?.error
            ? formatCurrencyString(formatEther(dataContract[1]?.result), 3)
            : "???"}{" "}
          sVIC
        </SecondTitle>

        <SecondTitle fontSize="12px">
          Confirm this transaction in your wallet
        </SecondTitle>
      </Modal>
    </Main>
  );
}
