import {Offcanvas} from 'react-bootstrap'
import WalletButton from "../WalletButton"
import {
  WrapHeader,
  DivStyled,
  NavLinkStyled,
  HeaderMobile,
  MenuToggle,
  NavLink2,
  LogoStyled,
} from "./styled"
import { FiMenu } from "react-icons/fi"
import { useState } from "react";
import Logo from '../../asset/vicpool2.png'
import config from "../../config.json"
import {useAccount} from "wagmi"

const isTestnet = config.isTestnet

let AllTabs = ['Stake', 'Withdrawals', 'Explorer']

if (isTestnet) {
  AllTabs.push('Faucet')
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isConnected }: any = useAccount();

  if (isConnected) {
    if (!AllTabs.includes('Migrate')) {
      AllTabs.push('Migrate')
    }
  }

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return (
    <WrapHeader>
      <DivStyled>
        <LogoStyled src={Logo} height={50}/>
        {AllTabs.map((tab, i) => (
          <NavLinkStyled key={i} to={tab.toLocaleLowerCase()}>{tab}</NavLinkStyled>
        ))}
      </DivStyled>
      <HeaderMobile>
        <MenuToggle onClick={handleMobileMenuOpen}>
          <FiMenu size={24} />
        </MenuToggle>
        <Offcanvas
          show={mobileMenuOpen}
          onHide={() => setMobileMenuOpen(false)}
        >
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
            <ul className="main-menu list-unstyled" style={{ width: "80%" }}>
              {AllTabs.map((tab, i) => (
                <li>
                  <NavLink2 key={i} href={tab.toLocaleLowerCase()}>{tab}</NavLink2>
                </li>
              ))}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </HeaderMobile>
      <WalletButton />
    </WrapHeader>
  );
}
