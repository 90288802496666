import { useCallback } from "react"
import axios from "axios"

export const useCoinPrice = (): (() => Promise<number>) => {
  return useCallback(async (): Promise<number> => {
    let result = 0

    try {
      const response = await axios.get(
        `https://api-liquid.vicpool.fi/stats/vic-price`
      )
      if (response.status === 200 && response.data) {
        result = response.data.vic
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])
}
