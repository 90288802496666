import styled from "styled-components";
import { deviceMax, lightTheme } from "../../themes";

export const Main = styled.main`
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 320px;
  width: 100%;
  padding: 0 32px;
  max-width: 1140px;
  @media (${deviceMax.mobileL}) {
    max-width: 100%;
    padding: 1rem;
  }
`;

export const Text = styled.p<{ color?: string; textAlign?: string }>`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6em;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryColor2)};
  align-items: center;
  display: flex;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
  @media (${deviceMax.mobileL}) {
    display: block;
  }
`;
export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    borderRadius: "4px",
    backgroundColor: lightTheme.greenColor,
    fontSize: 12,
    color: lightTheme.MainColor,
    maxWidth: 200,
  }),
  control: (control: any, state: any) => ({
    ...control,
    background: lightTheme.greenBackgroundColor,
    // borderColor: "blue",
    borderWidth: "1px",
    // minWidth: '185px',
    color: lightTheme.MainColor,
    justifyContent: "center",
    fontWeight: '600',
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    backgroundColor: lightTheme.secondBackgroundColor,
    color: !isSelected ? lightTheme.PrimaryColor : lightTheme.MainColor,
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: lightTheme.MainColor,
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: 0,
    backgroundColor: lightTheme.secondBackgroundColor,
  }),
};
