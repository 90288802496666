import TransactionTable from '../../components/TransactionTable'
import {SecondTitle, Title, WrapText} from "../Stake/styled";
import React from "react";

export default function Explorer() {

  return (
    <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <WrapText>
        <Title>VicPool Explorer</Title>
        <SecondTitle>Show all (or your) transactions in VicPool</SecondTitle>
      </WrapText>
      <TransactionTable/>
    </div>
  );
}
