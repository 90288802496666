import React, { useEffect, useState } from "react";
import {
  Main, Box, Input, WrapText, WrapDetail, Detail,
  SpanStyled, Title, SecondTitle, Box2, TitleBox2, BalanceBox2,
  Form, MaxButton, FullButton,
} from "../Stake/styled";
import { useWeb3Modal } from "@web3modal/react";
import {
  useAccount, useBalance, useContractReads, useNetwork,
} from "wagmi";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import Alert from 'react-bootstrap/Alert'

import dataAbi from "../../abi/88/abi.json";
import {
  formatCurrencyString,
  formatEther,
  formatString,
  parseEther,
} from "../../utils/utils";
import config from "../../config.json";
import { DEFAULT_CHAIN_ID, TX_FEE_STAKE } from "../../contants";

import { Tooltip } from "react-tooltip";
import { FaRegQuestionCircle } from "react-icons/fa";

import Modal from "../../components/Modal";
import WrongNetwork from "../../components/WrongNetwork";
export default function Stake() {
  const MIN_STAKE = 50000;
  const { open } = useWeb3Modal();
  const { address, isConnected }: any = useAccount();
  const { data: balance } = useBalance({ address, watch: true });
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState("");
  const [dataContract, setDataContract] = useState<any>([]);
  const [disable, setDisable] = useState(true);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');

  const { chain } = useNetwork();

  const chainId: string = !chain?.unsupported
    ? chain?.id.toString() || DEFAULT_CHAIN_ID.toString()
    : DEFAULT_CHAIN_ID.toString(); //@ts-ignore
  const urlBaseScanDefault =
    //@ts-ignore
    config[chainId]?.configChain.blockExplorers.default.url;

  const { data: dataContractHook } = useContractReads({
    contracts: [
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "getSVicAmount",
        args: ['1000000000000000000'],
      },
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "getSVicAmount",
        args: [parseEther(value === "" ? "0" : value)],
      },
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "daoFee",
      },
    ],
    //@ts-ignore
    watch: true,
  });
  useEffect(() => {
    if (dataContractHook) setDataContract(dataContractHook);
  }, [dataContractHook]);

  useEffect(() => {
    setValue("");
    setDisable(true);
  }, [address, chain]);

  const handleSubmit = async () => {
    setShowModal(true);
    try {

      const { request } = await prepareWriteContract({
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "depositForNewPool",
        //@ts-ignore
        args: [
          '0x4E1eFe04D698aBb3949AB1D88CD7A8B15d893e47',
        ],
        //@ts-ignore
        value: parseEther(value),
      });
      const { hash } = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash,
      });
      if (waitForTransactionData.status) {
        setShowModal(false);
        setTxHash(hash);
        setSuccess(true)
      }
    } catch (e: any) {
      console.error(e);
      setFail(true)
      setShowModal(false)
    }
  }

  const handleMax = () => {
    const balanceMinusFee =
      parseFloat(balance?.formatted || "0") - TX_FEE_STAKE > 0
        ? parseFloat(balance?.formatted || "0") - TX_FEE_STAKE
        : 0;
    onChangeValue(balanceMinusFee.toString());
  };
  const onChangeValue = (text: string) => {
    setValue(text);
    text.length > 0 &&
    parseFloat(text) >= MIN_STAKE &&
    parseFloat(balance?.formatted || "0") > parseFloat(text)
      ? setDisable(false)
      : setDisable(true);
  };

  return (
    <Main>
      <WrapText>
        <Title>Run New Pool</Title>
        <SecondTitle>Stake VIC and receive sVIC while staking</SecondTitle>
      </WrapText>
      <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        <p>
          Congratulations! You have successfully staked {value} VIC.
          More <Alert.Link rel="noopener" href={
          chain
            ? chain?.blockExplorers?.default.url + "/tx/" + txHash
            : urlBaseScanDefault + "/tx/" + txHash
        }>transaction detail</Alert.Link>.
        </p>
      </Alert>
      <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
        <Alert.Heading>Failed!</Alert.Heading>
        <p>
          Ops! There are something wrong. Please try again later
        </p>
      </Alert>
      {isConnected && !chain?.unsupported && (
        <Box2>
          <TitleBox2>Available to stake</TitleBox2>
          <BalanceBox2 paddingBottom="1rem">
            {" "}
            {formatCurrencyString(parseFloat(balance?.formatted || "0"))} VIC
          </BalanceBox2>
        </Box2>
      )}
      <WrongNetwork />
      <Box>
        <Form disable={disable} value={value}>
          <Input
            placeholder="VIC amount"
            onChange={(e) => onChangeValue(e.target.value)}
            value={value}
            type={"number"}
            min={0}
            onKeyPress={(e) => {
              if (e.code === "Minus") {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              //@ts-ignore
              const clipboardData = e.clipboardData || window.clipboardData;
              const pastedData = parseFloat(clipboardData.getData("text"));

              if (pastedData < 0) {
                e.preventDefault();
              }
            }}
          ></Input>
          <MaxButton onClick={() => handleMax()}>
            <span>Max</span>
          </MaxButton>
        </Form>

        {isConnected ? (
          <FullButton onClick={() => handleSubmit()} disabled={disable}>
            {parseFloat(value) < MIN_STAKE
              ? `Min stake is ${MIN_STAKE}`
              : parseFloat(balance?.formatted || "0") < parseFloat(value)
              ? "Insufficient VIC balance"
              : value.length === 0
              ? "Enter an amount"
              : "New Pool"}
          </FullButton>
        ) : (
          <FullButton onClick={() => open()}>Connect Wallet</FullButton>
        )}

        <WrapDetail>
          <Detail>
            <SpanStyled>You will receive</SpanStyled>
            <SpanStyled>
              {dataContract.length > 0 && !dataContract[1]?.error
                ? formatString(formatEther(dataContract[1]?.result).toString())
                : "0"}{" "}
              sVIC
            </SpanStyled>
          </Detail>
          <Detail>
            <SpanStyled>Exchange rate</SpanStyled>
            <SpanStyled textAlign="right">
              1 VIC ={" "}
              {dataContract.length > 0 && !dataContract[0]?.error
                ? formatString(formatEther(dataContract[0]?.result).toString())
                : "???"}{" "}
              sVIC
            </SpanStyled>
          </Detail>
          {/* <Detail>
            <SpanStyled>Max transaction cost</SpanStyled>
            <SpanStyled>???$</SpanStyled>
          </Detail> */}
          <Detail>
            <SpanStyled>
              Reward fee{" "}
              <FaRegQuestionCircle
                style={{ marginLeft: 5 }}
                data-tooltip-id="annual-percentage-rate"
                data-tooltip-content={
                  "Please note: this fee applies to staking rewards only, and is NOT taken from your staked amount."
                }
              />
              <Tooltip id="annual-percentage-rate" />
            </SpanStyled>
            <SpanStyled>
              {dataContract.length > 0 && !dataContract[2]?.error
                ? parseFloat(dataContract[2]?.result) / 100
                : "???"}
              %
            </SpanStyled>
          </Detail>
        </WrapDetail>
      </Box>
      <Modal
        show={showModal}
        onRequestClose={() => setShowModal(false)}
        error={false}
      >
        <Title fontSize="20px">You are now staking {value} VIC</Title>
        <SecondTitle fontSize="14px">
          Staking {value} VIC. You will receive{" "}
          {dataContract.length > 0 && !dataContract[1]?.error
            ? formatEther(dataContract[1]?.result)
            : "???"}{" "}
          sVIC
        </SecondTitle>

        <SecondTitle fontSize="12px">
          Confirm this transaction in your wallet
        </SecondTitle>
      </Modal>
    </Main>
  );
}
