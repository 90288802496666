import configJson from "./config.json";
import { Chain } from "@wagmi/core";

export const tomoTestnet = {
  id: configJson[89].configChain.id,
  name: configJson[89].configChain.name,
  network: configJson[89].configChain.network,
  nativeCurrency: configJson[89].configChain.nativeCurrency,
  rpcUrls: configJson[89].configChain.rpcUrls,
  blockExplorers: configJson[89].configChain.blockExplorers,
  contracts: {
    multicall3: {
      address: `0x${configJson[89].configChain.contracts.multicall3.address}`,
      blockCreated:
        configJson[89].configChain.contracts.multicall3.blockCreated,
    },
  },
} as const satisfies Chain;

export const tomo = {
  id: configJson[88].configChain.id,
  name: configJson[88].configChain.name,
  network: configJson[88].configChain.network,
  nativeCurrency: configJson[88].configChain.nativeCurrency,
  rpcUrls: configJson[88].configChain.rpcUrls,
  blockExplorers: configJson[88].configChain.blockExplorers,
  contracts: {
    multicall3: {
      address: `0x${configJson[88].configChain.contracts.multicall3.address}`,
      blockCreated:
        configJson[88].configChain.contracts.multicall3.blockCreated,
    },
  },
} as const satisfies Chain;
export const PROJECT_ID = configJson.PROJECT_ID;

//  config network
export const CHAINS = [tomo];
export const DEFAULT_CHAIN = tomo;
export const DEFAULT_CHAIN_ID = DEFAULT_CHAIN.id;

export const MIN_STAKE = 10;

export const TX_FEE_STAKE = 0.0002; // 0.000055
// export const TX_FEE_WITHDRAWALS = 0.00015;      // 0.00011
