import React from "react"
import Main from "./Main"
import ActionDetail from "./ActionDetail"

function Actions(props: any): JSX.Element {
  // @ts-ignore
  const { actions } = props
  if (actions.hasOwnProperty('main')) {
    return (
      <div>
        <Main main={actions.main}/>
        <ActionDetail actions={actions.explains}/>
      </div>
    )
  } else {
    return (
      <div>
        <ActionDetail actions={actions.explains}/>
      </div>
    )
  }
}

export default Actions
