import React, { useEffect, useState } from "react";
import {useSearchParams} from 'react-router-dom'
import {
  Main, Box, Input, WrapText, WrapDetail, Detail, SpanStyled, Title,
  SecondTitle, Box2, TitleBox2, BalanceBox2, Line, Row, Row2, Row3, Tab,
  ItemTab, NavLinkStyled, WithDrawalStatus, Form, MaxButton, FullButton, RequestClaimLink
} from "../Stake/styled";
import { useWeb3Modal } from "@web3modal/react";
import {
  useAccount, useContractReads, useContractRead,
  useNetwork, erc20ABI, useBlockNumber,
} from "wagmi";
import dataAbi from "../../abi/88/abi.json";
import { formatEther, formatString, parseEther } from "../../utils/utils";
import { DEFAULT_CHAIN_ID } from "../../contants";
import config from "../../config.json";
import { Tooltip } from "react-tooltip";
import { FaRegQuestionCircle } from "react-icons/fa";
import Modal from "../../components/Modal";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import { useRequestWithdrawal } from "../../components/hooks/useRequestWithdrawal";
import { PendingIcon, ReadyIcon } from "../../icon/icon";
import WrongNetwork from "../../components/WrongNetwork";
import {lightTheme} from "../../themes";
import Alert from "react-bootstrap/Alert";
import Button from 'react-bootstrap/Button'

export default function Withdrawals(props: any) {
  const { open } = useWeb3Modal();
  const [queryParameters] = useSearchParams()

  const { address, isConnected }: any = useAccount();
  const { chain } = useNetwork();
  const [showModal, setShowModal] = useState(false);
  const [showModalClaim, setShowModalClaim] = useState(false);
  const [error, setError] = useState(false);

  const [dataContract, setDataContract] = useState<any>([]);
  const [dataGetAmountTomo, setDataGetAmountTomo] = useState<any>(0);
  const [value, setValue] = useState("");
  const [disable, setDisable] = useState(true);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [message, setMessage] = useState('');
  const [txHash, setTxHash] = useState('');
  const [tab, setTab] = useState('');

  const chainId: string = !chain?.unsupported
    ? chain?.id.toString() || DEFAULT_CHAIN_ID.toString()
    : DEFAULT_CHAIN_ID.toString();
  //@ts-ignore
  const sVicAddress = config[chainId].sVicAddress;
  const urlBaseScanDefault =
    //@ts-ignore
    config[chainId]?.configChain.blockExplorers.default.url;

  const getWithdrawal = useRequestWithdrawal(value, address, chainId);

  function setTabStatus(status: any) {
    setTab(status)
  }

  const {
    data: dataContractHook,
  } = useContractReads({
    contracts: [
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "getCurrentSVicRate",
      },

      {
        address: `0x${sVicAddress.replace("0x", "")}`,
        //@ts-ignore
        abi: erc20ABI,
        functionName: "balanceOf",
        args: [address],
      },
      {
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "getWithdrawalStates",
        args: [address],
      },
    ],
    //@ts-ignore
    watch: true,
  });
  const { data: dataGetAmountTomoHook, refetch } = useContractRead({
    address: `0x${dataAbi.address.replace("0x", "")}`,
    //@ts-ignore
    abi: dataAbi.abi,
    functionName: "getVicAmount",
    args: [parseEther(value === "" ? "0" : value)],
  });

  const blockNumber =
    useBlockNumber({
      watch: true,
    }) || 0;
  useEffect(() => {
    if (dataContractHook) setDataContract(dataContractHook);
    else setDataContract([]);
    setDataGetAmountTomo(dataGetAmountTomoHook);
    if (queryParameters.has('tab')) {
      setTab(queryParameters.get('tab') || '')
    }
  }, [dataContractHook, dataGetAmountTomoHook, tab]);

  const handleUnstake = async () => {
    setError(false);
    setShowModal(true);
    try {
      const {
        amountsForUnstake,
        normalAddress,
        normalAmount,
        poolAddress,
        poolAmount,
        expiryTime,
        rs,
        v,
      } = await getWithdrawal();

      const { request } = await prepareWriteContract({
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "unstake",
        //@ts-ignore
        args: [
          amountsForUnstake, // value array
          normalAddress,
          normalAmount,
          poolAddress,
          poolAmount,
          expiryTime,
          address,
          rs,
          v,
        ],
        //@ts-ignore
      });
      const { hash } = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash,
      });
      if (waitForTransactionData.status) {
        setShowModal(false);
        setMessage(`You have successfully unstaked ${value} VIC.`)
        setTxHash(hash)
        setSuccess(true)
        setValue("");
        setDisable(true);
        refetch();
      }
    } catch (e: any) {
      console.warn(e);
      setShowModal(false)
      setFail(true)
    }
  };

  const handleClaim = async () => {
    setShowModalClaim(true);

    try {
      const { request } = await prepareWriteContract({
        address: `0x${dataAbi.address.replace("0x", "")}`,
        //@ts-ignore
        abi: dataAbi.abi,
        functionName: "withdraw",
        //@ts-ignore
        args: [address],
      });
      const { hash } = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash,
      });
      if (waitForTransactionData.status) {
        setShowModalClaim(false);
        setMessage(`You have successfully claimed your staked.`)
        setTxHash(hash)
        setSuccess(true)
      }
    } catch (e) {
      console.warn(e);
      setShowModalClaim(false);
      setFail(true)
    }
  };

  const balanceStTomo =
    dataContract.length > 0 && !dataContract[1]?.error
      ? formatEther(dataContract[1]?.result).toString()
      : "0";
  const onChangeValue = (text: string) => {
    setValue(text);
    text.length > 0 &&
    parseFloat(text) > 0 &&
    parseFloat(balanceStTomo) >= parseFloat(text) &&
    !chain?.unsupported
      ? setDisable(false)
      : setDisable(true);
  };

  const handleMax = () => {
    onChangeValue(balanceStTomo);
  };

  const Request = () => (
    <div>
      <TitleBox2>My request</TitleBox2>
      <Row>
        <Row3
          data-tooltip-id="ready-to-claim"
          data-tooltip-content={"Ready to claim"}
        >
          <ReadyIcon />
        </Row3>

        <Tooltip id="ready-to-claim" />
        <BalanceBox2
          borderRight="1px solid rgba(255, 255, 255, 0.3)"
          style={{ paddingRight: 10, marginRight: 10 }}
        >
          {getNumberReadyRequest(
            dataContract[2]?.result,
            Number(blockNumber?.data)
          )}
        </BalanceBox2>
        <Row3 data-tooltip-id="pending" data-tooltip-content={"Pending"}>
          <PendingIcon />
        </Row3>
        <Tooltip id="pending" />
        <BalanceBox2>
          {getNumberPendingRequest(
            dataContract[2]?.result,
            Number(blockNumber?.data)
          )}
        </BalanceBox2>
      </Row>
    </div>
  );
  return (
    <Main>
      <WrapText>
        <Title>Withdrawals</Title>
        <SecondTitle>Request sVIC withdrawal and claim VIC</SecondTitle>
      </WrapText>
      <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        <p>
          Congratulations! {message}
          More <Alert.Link rel="noopener" href={
          chain
            ? chain?.blockExplorers?.default.url + "/tx/" + txHash
            : urlBaseScanDefault + "/tx/" + txHash
        }>transaction detail</Alert.Link>.
        </p>
      </Alert>
      <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
        <Alert.Heading>Failed!</Alert.Heading>
        <p>
          Ops! There are something wrong. Please try again later
        </p>
      </Alert>
      <Tab>
        <ItemTab>
          <RequestClaimLink
              background={tab === 'claim'? "" : lightTheme.greenColor}
              onClick={() => setTabStatus('')}>Request</RequestClaimLink>
          <RequestClaimLink
              background={tab !== 'claim'? "" : lightTheme.greenColor}
              onClick={() => setTabStatus('claim')}>Claim</RequestClaimLink>
        </ItemTab>
      </Tab>
      {isConnected &&
        !chain?.unsupported &&
        ((tab !== "claim") ? (
          <Box2>
            <div>
              <TitleBox2>sVIC Balance</TitleBox2>
              <BalanceBox2>
                {" "}
                {dataContract.length > 0 && !dataContract[1]?.error
                  ? formatString(
                      formatEther(dataContract[1]?.result).toString()
                    )
                  : "0"}{" "}
                sVIC
              </BalanceBox2>
            </div>
            <Line />
            <div>
              <Row>
                <Request />
              </Row>
            </div>
          </Box2>
        ) : (
          <Box2>
            <div>
              <TitleBox2>Available to claim</TitleBox2>
              <BalanceBox2>
                {formatString(
                  getTotalClaim(
                    dataContract[2]?.result,
                    Number(blockNumber?.data)
                  )
                )}{" "}
                VIC
              </BalanceBox2>
            </div>
            <Line />
            <div>
              <Row style={{ alignItems: "normal" }}>
                <Request />
                <div>
                  <TitleBox2>My pendding amount</TitleBox2>
                  <BalanceBox2>
                    {formatString(
                      getTotalPending(
                        dataContract[2]?.result,
                        Number(blockNumber?.data)
                      )
                    )}{" "}
                    sVIC
                  </BalanceBox2>
                </div>
              </Row>
            </div>
          </Box2>
        ))}
      <WrongNetwork />
      {(tab !== "claim") ? (
        <Box>
          <Form disable={disable} value={value}>
            <Input
              placeholder="sVIC amount"
              onChange={(e) => onChangeValue(e.target.value)}
              value={value}
              type="number"
              min={0}
              onKeyPress={(e) => {
                if (e.code === "Minus") {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                //@ts-ignore
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = parseFloat(clipboardData.getData("text"));

                if (pastedData < 0) {
                  e.preventDefault();
                }
              }}
            ></Input>
            <MaxButton onClick={() => handleMax()}>
              <span>Max</span>
            </MaxButton>
          </Form>

          <Row2>
            {/* <SpanStyled>Name</SpanStyled> */}
            <BalanceBox2>
              {" "}
              {dataGetAmountTomo
                ? formatString(formatEther(dataGetAmountTomo).toString())
                : "0"}{" "}
              VIC{" "}
              <FaRegQuestionCircle
                style={{ marginLeft: 5 }}
                data-tooltip-id="max-unlock-cost"
                data-tooltip-content="The final amount of claimable VIC can differ"
              />
              <Tooltip
                id="max-unlock-cost"
                style={{ maxWidth: "100%", fontSize: 12 }}
              />
            </BalanceBox2>
          </Row2>
          {isConnected ? (
            <FullButton onClick={() => handleUnstake()} disabled={disable}>
              {parseFloat(balanceStTomo) < parseFloat(value)
                ? "Insufficient sVIC balance"
                : value.length === 0
                ? "Enter an amount"
                : "Request withdrawal"}
            </FullButton>
          ) : (
            <FullButton onClick={() => open()}>Connect Wallet</FullButton>
          )}
          <WrapDetail>
            <Detail>
              <SpanStyled>Max unlock cost </SpanStyled>
              <SpanStyled>Free</SpanStyled>
            </Detail>
            {/* <Detail>
              <SpanStyled>Max transaction cost</SpanStyled>
              <SpanStyled>???$</SpanStyled>
            </Detail> */}
            {/* <Detail>
              <SpanStyled>Allowance</SpanStyled>
              <SpanStyled>??? sVIC</SpanStyled>
            </Detail> */}
            <Detail>
              <SpanStyled>Exchange rate</SpanStyled>
              <SpanStyled>
                1 sVIC ={" "}
                {dataContract.length > 0 && !dataContract[0]?.error
                  ? formatString(
                      formatEther(dataContract[0]?.result).toString()
                    )
                  : "???"}{" "}
                VIC
              </SpanStyled>
            </Detail>
          </WrapDetail>
        </Box>
      ) : (
        <Box>
          {dataContract.length === 0 ||
          dataContract[2]?.error ||
          dataContract[2].result.length === 0 ||
          chain?.unsupported ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "8rem",
              }}
            >
              <SpanStyled>No withdrawal requests detected.</SpanStyled>
            </div>
          ) : (
            dataContract.length > 0 &&
            !dataContract[2]?.error &&
            dataContract[2]?.result.map((withdrawState: any, i: any) => (
              <Row
                key={i}
                padding="0.8rem"
                marginBottom="0.8rem"
                backgroundColor="rgba(255, 255, 255, 0.05)"
                borderRadius={"10px"}
              >
                <Row3>
                  <SpanStyled>
                    {" "}
                    {formatString(
                      formatEther(withdrawState.withdrawalAmount).toString()
                    )}{" "}
                    VIC{" "}
                  </SpanStyled>
                </Row3>
                <WithDrawalStatus
                  status={
                    //@ts-ignore
                    blockNumber?.data >= withdrawState.blockNumber
                      ? withdrawState.isWithdrawn
                        ? "claimed"
                        : "success"
                      : "pending"
                  }
                >
                  {/* <SpanStyled> */}
                  {
                    //@ts-ignore
                    blockNumber?.data >= withdrawState.blockNumber
                      ? withdrawState.isWithdrawn
                        ? "Claimed"
                        : "Ready"
                      : "Pending"
                  }
                  <FaRegQuestionCircle
                    data-tooltip-id="max-unlock-cost"
                    data-tooltip-content={
                      //@ts-ignore
                      blockNumber?.data >= withdrawState.blockNumber
                        ? withdrawState.isWithdrawn
                          ? "Claimed!"
                          : "Ready to claim!"
                        : "Current withdrawal period is 50 hours."
                    }
                  />
                  <Tooltip id="max-unlock-cost" />
                  {/* </SpanStyled> */}
                </WithDrawalStatus>
              </Row>
            ))
          )}

          {isConnected ? (
            <FullButton
              onClick={() => handleClaim()}
              disabled={
                parseFloat(
                  getTotalClaim(
                    dataContract[2]?.result,
                    Number(blockNumber?.data)
                  )
                ) <= 0 || chain?.unsupported
              }
            >
              Claim{" "}
              {formatString(
                getTotalClaim(
                  dataContract[2]?.result,
                  Number(blockNumber?.data)
                )
              )}{" "}
              VIC{" "}
            </FullButton>
          ) : (
            <FullButton onClick={() => open()}>Connect Wallet</FullButton>
          )}

          <WrapDetail>
            {/* <Detail>
              <SpanStyled>Max transaction cost</SpanStyled>
              <SpanStyled>???$</SpanStyled>
            </Detail> */}
          </WrapDetail>
        </Box>
      )}

      <Modal
        show={showModal}
        onRequestClose={() => setShowModal(false)}
        error={error}
      >
        <>
          <Title fontSize="20px">
            You are requesting withdrawal for {value} sVIC
          </Title>
          <SecondTitle fontSize="14px">
            Requesting withdrawal for {value} sVIC
          </SecondTitle>
          <SecondTitle fontSize="12px">
            Confirm this transaction in your wallet
          </SecondTitle>
        </>
      </Modal>

      <Modal
        show={showModalClaim}
        onRequestClose={() => setShowModalClaim(false)}
        error={error}
      >
        <>
          <Title fontSize="20px">
            You are now claiming{" "}
            {formatString(
              getTotalClaim(
                dataContract[2]?.result,
                Number(blockNumber?.data)
              )
            )}{" "}
            VIC
          </Title>
          <SecondTitle fontSize="14px">Processing your request</SecondTitle>

          <SecondTitle fontSize="12px">
            Confirm this transaction in your wallet
          </SecondTitle>
        </>
      </Modal>
    </Main>
  );

  function getTotalClaim(arr: any, blockNumber: number) {
    if (!arr) return "0";
    const sum = arr
      .filter(
        (e: any) => e.isWithdrawn === false && blockNumber >= e.blockNumber
      )
      .reduce(
        (partialSum: any, a: any) => partialSum + BigInt(a.withdrawalAmount),
        BigInt(0)
      );
    return formatEther(sum).toString();
  }
  function getTotalPending(arr: any, blockNumber: number) {
    if (!arr) return "0";
    const sum = arr
      .filter((e: any) => e.isWithdrawn === false && blockNumber < e.blockNumber)
      .reduce(
        (partialSum: any, a: any) => partialSum + BigInt(a.withdrawalAmount),
        BigInt(0)
      );
    return formatEther(sum).toString();
  }
  function getNumberReadyRequest(arr: any, blockNumber: number) {
    if (!arr) return 0;
    const length = arr.filter(
      (e: any) => e.isWithdrawn === false && blockNumber >= e.blockNumber
    ).length;
    return length;
  }
  function getNumberPendingRequest(arr: any, blockNumber: number) {
    if (!arr) return 0;
    const length = arr.filter(
      (e: any) => e.isWithdrawn === false && blockNumber < e.blockNumber
    ).length;
    return length;
  }
}
