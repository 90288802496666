import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { deviceMax, device, lightTheme } from "../../themes";

export enum ColorStatus {
  success = "rgb(83, 186, 149)",
  pending = "rgb(236, 134, 0)",
  claimed = "rgb(0,163,255)",
}
export enum BackgroundStatus {
  success = "rgba(83, 186, 149,0.16)",
  pending = "rgba(236, 134, 0, 0.16)",
  claimed = "rgba(0,163,255,0.16)",
}
export const Main = styled.main`
  position: relative;
  box-sizing: border-box;
  min-width: 320px;
  width: 100%;
  padding: 0 32px;
  max-width: 560px;
  margin: 0 auto;
  @media (${deviceMax.mobileL}) {
    max-width: 100%;
    padding: 1rem;
  }
`;
export const WrapText = styled.div`
  text-align: center;
  padding: 1rem;
`;
export const Box = styled.div`
  font-weight: 400;
  font-size: 25px;
  line-height: 1.6em;
  border-radius: 0 0 20px 20px;
  margin: 0px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  margin-bottom: 1rem;
  @media (${deviceMax.mobileL}) {
    max-width: 100%;
    padding: 1rem;
  }
`;
export const Form = styled.div<{ disable: boolean; value: string }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;

  width: 100%;
  border-radius: 10px;
  height: 60px;
  padding: 0;

  border: 2px solid;
  border-color: ${lightTheme.greenColor};
`;
export const Input = styled.input`
  height: 100%;
  width: 100%;
  border-width: 0;
  margin-left: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  color: rgb(255, 255, 255);
`;
export const Button = styled.button<{ disabled?: boolean }>`
  border-radius: 12px;
  background-color: ${lightTheme.greenBackgroundColor};
  width: 100%;
  color: ${lightTheme.MainColor};
  margin: 5px 3px;
  padding: 10px;
  max-width: 100px;
  font-weight: 700;
  height: 40px;
  font-size: 14px;
  border-width: 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
export const FullButton = styled.button<{ disabled?: boolean }>`
  border-radius: 12px;
  background-color: ${lightTheme.greenBackgroundColor};
  width: 100%;
  color: ${lightTheme.MainColor};
  margin: 1rem 0;
  padding: 1rem;
  font-weight: 700;
  line-height: 1em;
  font-size: 14px;
  border-width: 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const MaxButton = styled.button<{ disabled?: boolean }>`
  box-sizing: border-box;
  margin: 0  10px 0 -61px;
  border: none;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  background: transparent;
  font-family: inherit;
  font-weight: 700;
  width: auto;
  line-height: 1em;
  font-size: 10px;
  border-radius: 6px;
  padding: 11px 16px;
  min-width: 50px;
  color: ${(props) => props.theme.mainBackgroundColor};
  background-color: ${(props) => props.theme.greenBackgroundColorRgba(0.8)};

  &:hover {
    background-color: ${(props) =>
      props.theme.greenBackgroundColor};
  }
`;

export const WrapDetail = styled.div`
  height: 100%;
`;

export const Detail = styled.div<{ marginHoz?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ marginHoz }) => (marginHoz ? marginHoz : "5px")};
  margin-top: ${({ marginHoz }) => (marginHoz ? marginHoz : "5px")};
  font-size: 15px;
`;

export const SpanStyled = styled.div<{ color?: string; textAlign?: string }>`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6em;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryColor2)};
  align-items: center;
  display: flex;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
`;
export const LinkStyled = styled.a<{ color?: string }>`
  font-weight: 400;
  line-height: 1.6em;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryColor2)};
  text-decoration: none;
  text-align: right;
`;
export const Title = styled.h1<{ fontSize?: string }>`
  font-weight: 800;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "26px")};
  margin-bottom: 0.2em;
  line-height: 1.2em;
  text-align: center;
`;
export const SecondTitle = styled.h1<{ fontSize?: string }>`
  font-weight: 500;
  color: ${(props) => props.theme.PrimaryColor2};
  margin-bottom: 16px;
  line-height: 1.5em;
  text-align: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "")};
`;
export const Box2 = styled.div`
  background: rgba(255, 255, 255, 0.05);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6em;
  border-radius: 20px 20px 0 0;
  margin: 0 ;
  padding: 32px 32px 0 32px;
  box-shadow: none;
  color: white;
`;
export const Box3 = styled.div`
  display: flex;
  text-decoration: none;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  margin-bottom: 15px;
`;

export const FloatButton = styled.button`
  background-color: ${lightTheme.greenBackgroundColor};
  width: 100px;
  color: ${lightTheme.MainColor};
  margin: 4px 0;
  font-weight: 700;
  line-height: 1em;
  font-size: 14px;
  border-width: 0;

  @media (${deviceMax.mobileL}) {
    clear: both;
    border-radius: 12px;
    padding: 1rem;
  }
`;
export const PoolLogo = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
`;
export const PoolInfo = styled.div`
  width: calc(100% - 75px);
  padding-left: 10px;
`;
export const PoolName = styled.div`
  font-size: 19px;
  font-weight: bold;
  line-height: 1.6em;
  color: rgb(255, 255, 255);
  align-items: center;
`;
export const YouStake = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  margin-top: 8px;
  font-weight: 300;
`;
export const TransferMigrateBtn = styled.div`

  @media (${device.tablet}) {
    display: flex;
  }
`;


export const TitleBox2 = styled.span``;

export const BalanceBox2 = styled.div<{
  color?: string;
  paddingBottom?: string;
  borderRight?: string;
}>`
  margin-top: 2px;
  font-size: 18px;
  line-height: 1.4em;
  font-weight: 800;
  white-space: nowrap;
  color: ${({ color }) => (color ? color : "")};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : ""};
  border-right: ${({ borderRight }) => (borderRight ? borderRight : "")};
  display: flex;
  align-items: center;
`;
export const Line = styled.div`
  box-sizing: border-box;
  list-style: none;
  opacity: 0.1;
  padding: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  border-top: 1px solid currentcolor;
  width: 100%;
  height: 0;
  margin: 10px;
`;

export const Row = styled.div<{
  padding?: string;
  marginBottom?: string;
  backgroundColor?: string;
  borderRadius?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : "")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "")};
`;
export const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  min-height: 82px;
  background-color: transparent;
  border-radius: 10px;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  padding: 0px 0.8rem;
  margin-top: 1rem;

  border: 2px solid;
  border-color: ${lightTheme.greenColor};
`;
export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Tab = styled.div`
  width: 268px;
  height: 44px;
  border-radius: 22px;
  position: relative;

  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  margin: 0px auto 24px;
`;

export const ItemTab = styled.div`
  height: 40px;
  border-radius: 20px;
  left: calc(100% - 134px);
  transition: left 0.3s ease 0s;
  display: flex;
  justify-content: space-around;
  border: 1px solid rgba(0, 10, 61, 0.22);
  padding: 2px 2px;
`;
export const NavLinkStyled = styled(NavLink)<{ background?: string }>`
  z-index: 2;
  margin: 0;
  transition: opacity 0.3s ease 0s;
  font-weight: 600;
  line-height: 1.6em;
  text-decoration: none;
  flex: 1 1 0%;
  text-align: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
  background: ${({ background }) => (background ? background : "")};
  border-radius: ${({ background }) => (background ? "20px" : "")};
  color: ${({ background, theme }) =>
    background ? '#fff' : theme.PrimaryColor2};
  &:hover {
    color: ${(props) => props.theme.mainBackgroundColor};
  }
`;
export const RequestClaimLink = styled.a<{ background?: string }>`
  z-index: 2;
  margin: 0;
  transition: opacity 0.3s ease 0s;
  font-weight: 600;
  line-height: 1.6em;
  text-decoration: none;
  flex: 1 1 0%;
  text-align: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
  background: ${({ background }) => (background ? background : "")};
  border-radius: ${({ background }) => (background ? "20px" : "")};
  color: ${({ background, theme }) =>
    background ? '#fff' : theme.PrimaryColor2};
  &:hover {
    color: ${(props) => props.theme.mainBackgroundColor};
  }
`;
export const Label = styled.label`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6em;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryColor2)};
  flex-shrink: 0;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  line-height: 0;
  -webkit-box-align: center;
  align-items: center;
`;

export const WithDrawalStatus = styled.div<{ status: string }>`
  height: 24px;
  margin-left: auto;
  margin-right: 8px;
  padding: 2px 8px;
  gap: 8px;
  border-radius: 48px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: ${({ status }) =>
    //@ts-ignore
    BackgroundStatus[status]};
  color: ${({ status }) =>
    //@ts-ignore
    ColorStatus[status]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
`;
