import { ethers } from "ethers";

export const formatEther = (amount: string) =>
  parseFloat(ethers.formatEther(amount));

export const parseEther = (amount: string) => {
  try {
    return ethers.parseEther(amount);
  } catch {
    return BigInt(0);
  }
};
export const ethToWei = (amount: string) => {
  // try {
    return ethers.parseUnits(amount, 18);
  // } catch {
  //   return BigInt(0);
  // }
};

export const BigNumber = (amount: string | number) => {};

export const trimName = (
  name: string,
  start = 18,
  end = 16,
  total = 40
): string | undefined => {
  if (name?.length > total) {
    return `${name.substring(0, start)}...${name.substring(name.length - end)}`;
  } else {
    return name;
  }
};

export function formatString1(string: string, number: number = 7) {
  if (string) {
    // string = formatCurrencyString(parseFloat(string));
    const newString = string.slice(0, number);
    if (
      newString[newString.length - 1] === "." ||
      newString[newString.length - 1] === ","
    ) {
      const a = newString.slice(0, -1);
      return a;
    }
    return newString;
  }
  return string;
}

const FRACTIONAL_DIGITS = 5;

export const formatString = (amount: string, float = 7) => {
  if (parseFloat(amount) === 0) {
    return amount;
  }
  if (parseFloat(amount) < 0.00001) {
    return `< ${
      !FRACTIONAL_DIGITS
        ? "0"
        : `0.${"0".repeat((FRACTIONAL_DIGITS || 1) - 1)}1`
    }`;
  }

  return formatString1(amount, float);
};

export const formatCurrencyString = (number: number, minimumFractionDigits = 5) => {
  return number.toLocaleString("en-US", { minimumFractionDigits });
};

export function timeConverter(UNIX_timestamp: number) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
}
