import React, {useEffect, useState} from 'react'
import {Transaction} from '../../type/Transaction'
import RequestLink from './components/RequestLink'
import RequestTime from './components/RequestTime'
import {DEFAULT_CHAIN_ID} from "../../contants";
import {useAccount, useNetwork} from 'wagmi'
import Select from "react-select";
import {customStyles, Main} from "../../pages/Explorer/styled"
import config from '../../config.json'
import axios from 'axios'
import Actions from "./components/Actions"
import ReactPaginate from 'react-paginate'
import Table from 'react-bootstrap/Table'

const options = [
  { value: "all", label: "All transactions" },
  { value: "my-transactions", label: "My transactions" },
];

function Index(): JSX.Element {

  const { address }: any = useAccount();
  const { chain } = useNetwork();

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const chainId: string = !chain?.unsupported
    ? chain?.id.toString() || DEFAULT_CHAIN_ID.toString()
    : DEFAULT_CHAIN_ID.toString(); //@ts-ignore
  const [transactions, setTransactions] = useState<
    Transaction[]
  >([]);

const thead = ['TX', 'From', 'Action', 'Age']

  // pagination
  const [pageIndex, setPageIndex] = useState(0)
  const pageSize = 10
  const [totalPage, setTotalPage] = useState(1)

  async function getData(page: number, limit: number) {
    let data = {
      transactions: [],
      page: 1,
      limit: 20,
      totalPage: 1,
      totalRecords: 1
    }

    const filterUser = selectedOption.value === "all" ? "" : `/${address}`;
    try {
      // @ts-ignore
      const url = config[chainId].api + `/transaction` + filterUser + `?page=${page+1}&limit=${limit}`
      const response = await axios.get(url);
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }


    if (data.transactions.length > 0) {
      setTransactions(data.transactions);
      setTotalPage(data.totalPage)
    }
    else setTransactions([]);
  }

  const handlePageClick = (event: any) => {
    setPageIndex(event.selected)
    getData(event.selected, pageSize)
  };

  useEffect(() => {
    getData(pageIndex, pageSize);
  }, [selectedOption, pageSize, pageIndex]);

  return (

    <Main>
      <div
        className='select-my-transaction'
      >
        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          options={options}
          defaultValue={selectedOption}
          onChange={(option: any) => {
            setSelectedOption(option);
          }}
          placeholder='Rows per page'
          styles={customStyles}
          isSearchable={false}
        />
      </div>
      <div className='mb-5'>
        <Table responsive className='table-transaction'>
          <thead>
          <tr>
            {thead.map((item, index) => (
                <th key={index}>{item}</th>
            ))}
          </tr>
          </thead>
          <tbody>
            {transactions.map((tx, index) => (
              <tr key={index}>
                <td>
                  <div className='show-less-767'>Tx: </div>
                  <RequestLink name='tx' hash={tx.hash}/>
                </td>
                <td>
                  <div className='show-less-767'>From: </div>
                  <RequestLink name='address' hash={tx.from}/>
                </td>
                <td><Actions actions={tx.actions}/></td>
                <td>
                  <div className='show-less-767'>Age: </div>
                  <RequestTime time={tx.actionAt}/>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div id='paginate' className='d-inline float-end'>
          <ReactPaginate
            className='pagination'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            activeClassName='active'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </div>
        <div style={{height: '100px', width: '100%', display: "block", marginBottom: '5rem'}}></div>
        <div className='clearfix'></div>
      </div>
    </Main>
  )
}

export default Index
