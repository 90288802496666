import styled from "styled-components";
import { deviceMax } from "../../themes";

export const Main = styled.main`
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
  margin: 0px auto;
  min-width: 320px;
  width: 100%;
  padding: 0px 32px;
  max-width: 760px;
  margin: 0px auto;
  @media (${deviceMax.mobileL}) {
    max-width: 100%;
    padding: 1rem;
  }
`;
export const Row = styled.div<{
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "")};
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
