import "./App.css";
import "react-tooltip/dist/react-tooltip.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {ThemeProvider} from "styled-components";
import {lightTheme, GlobalStyles} from "./themes";

import {configureChains, WagmiConfig, createConfig} from "wagmi";
import {
  EthereumClient,
  w3mProvider,
} from "@web3modal/ethereum";
import {Web3Modal} from "@web3modal/react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Stake from "./pages/Stake";
import Withdrawals from "./pages/Withdrawals";
import NewPool from "./pages/NewPool";

import {CHAINS, PROJECT_ID, DEFAULT_CHAIN} from "./contants";
import Migrate from "./pages/Migrate";
import Explorer from "./pages/Explorer";
import Faucet from "./pages/Faucet";
import React from "react";
import config from "./config.json";
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const projectId = PROJECT_ID;
const chains = CHAINS;
const {publicClient} = configureChains(chains, [w3mProvider({projectId})]);
const wagmiConfig = createConfig({
  autoConnect: true,
  // connectors: w3mConnectors({ projectId, chains }),
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  const isTestnet = config.isTestnet;
  return (
    <div>
      <BrowserRouter>
        <WagmiConfig config={wagmiConfig}>
          <ThemeProvider theme={lightTheme}>
            <GlobalStyles theme={lightTheme}/>
            <Header></Header>
            <Routes>
              <Route path="/stake" element={<Stake />} />
              <Route path="/withdrawals" element={<Withdrawals />} />
              <Route path="/" element={<Stake />} />
              <Route path="/migrate" element={<Migrate />} />
              <Route path="/explorer" element={<Explorer />} />
              <Route path="/new-pool" element={<NewPool />} />
              <>
                {isTestnet ? (<Route path="/faucet" element={<Faucet />} />) : ("")}
              </>
            </Routes>
            <Footer/>
          </ThemeProvider>
        </WagmiConfig>
      </BrowserRouter>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={DEFAULT_CHAIN}
      />
    </div>
  );
}

export default App;
