import { default as ReactModal } from "react-modal"
import ReactLoading from "react-loading"
import { device, lightTheme } from "../../themes"
import { SlClose } from "react-icons/sl"
import styled from "styled-components"

export const Container = styled.div`
  min-width: 300px;
  // max-width: 90%
  padding: 2rem 5rem;
  @media (${device.mobileL}) {
    padding: 2rem 3rem
  }
  @media (${device.mobileS}) {
    padding: 1rem 1rem
  }
`
export const Close = styled(SlClose)`
  &:hover {
    color: ${(props) => props.theme.thirdBackgroundColor}
  }
`

interface IModalProps {
  show: boolean
  children?: any
  onRequestClose: () => void
  error: boolean
  isNoLoading?: boolean
}

function Modal(props: IModalProps): JSX.Element {
  const { show, children, onRequestClose, error, isNoLoading } = props

  return (
    <ReactModal
      isOpen={show}
      onAfterOpen={() => {}}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          inset: '0px',
          backgroundColor: 'rgba(255, 255, 255, 0.65)'
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 20,
          background: "rgb(25, 29, 52)"
        },
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Close size={20} onClick={() => onRequestClose()} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          {error ? (
            <div
              style={{
                borderRadius: "50%",
                border: "1px solid #e14d4d",
                padding: "1rem",
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="#e14d4d">
                <path d="M17.707 7.707a1 1 0 00-1.414-1.414L12 10.586 7.707 6.293a1 1 0 00-1.414 1.414L10.586 12l-4.293 4.293a1 1 0 101.414 1.414L12 13.414l4.293 4.293a1 1 0 001.414-1.414L13.414 12l4.293-4.293z"></path>
              </svg>
            </div>
          ) : !isNoLoading ? (
            <ReactLoading
              type={"spin"}
              color={lightTheme.thirdBackgroundColor}
              height={"15%"}
              width={"15%"}
            />
          ) : (
            <></>
          )}
        </div>
        {children}
      </Container>
    </ReactModal>
  )
}

export default Modal
