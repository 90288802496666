import React, { useState } from "react";
import {
  Main, Box, WrapText, Title, SecondTitle, Box2, TitleBox2, BalanceBox2, Line, Row, FullButton,
} from "../Stake/styled";
import { useWeb3Modal } from "@web3modal/react";
import {useAccount, useBalance, useNetwork} from "wagmi";
import Alert from 'react-bootstrap/Alert'

import {
  formatCurrencyString,
} from "../../utils/utils";
import config from "../../config.json";
import { DEFAULT_CHAIN_ID } from "../../contants";

import WrongNetwork from "../../components/WrongNetwork";
import axios from 'axios'

const fauceter = "0x5f9e66dad3a633fb2cb60b558c7918e46ddba246"
export default function Faucet() {
  const { open } = useWeb3Modal();
  const { address, isConnected }: any = useAccount();
  const { data: balance } = useBalance({ address: fauceter, watch: true });
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');

  const { chain } = useNetwork();


  const chainId: string = !chain?.unsupported
    ? chain?.id.toString() || DEFAULT_CHAIN_ID.toString()
    : DEFAULT_CHAIN_ID.toString(); //@ts-ignore
  const urlBaseScanDefault =
    //@ts-ignore
    config[chainId]?.configChain.blockExplorers.default.url;


  const handleSubmit = async () => {
    setFail(false)
    setSuccess(false)
    try {
      // @ts-ignore
      const url = config[chainId].api + `/faucet`
      const response = await axios.post(url, {user: address});
      if (response.status === 200 && response.data) {
        let hash = response.data.hash;
        setTxHash(hash)
        setSuccess(true)
      }
    } catch (error: any) {
      console.error(error);
      setFail(true)
    }
  }


  return (
    <Main>
      <WrapText>
        <Title>Faucet</Title>
        <SecondTitle>Faucet VIC to use liquid staking</SecondTitle>
      </WrapText>
      <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        <p>
          Congratulations! You have successfully faucet 1000 VIC.
          More <Alert.Link rel="noopener" href={
          chain
            ? chain?.blockExplorers?.default.url + "/tx/" + txHash
            : urlBaseScanDefault + "/tx/" + txHash
        }>transaction detail</Alert.Link>.
        </p>
      </Alert>
      <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
        <Alert.Heading>Failed!</Alert.Heading>
        <p>
          Ops! There are something wrong. Please make sure you never faucet before
        </p>
      </Alert>
      {isConnected && !chain?.unsupported && (
        <Box2>
          <TitleBox2>Fauceter</TitleBox2>
          <BalanceBox2 paddingBottom="1rem">
            {fauceter}
          </BalanceBox2>
          <Line />
          <Row>
            <div>
              <TitleBox2>Available to faucet</TitleBox2>
              <BalanceBox2 paddingBottom="1rem">
                {" "}
                {formatCurrencyString(parseFloat(balance?.formatted || "0"))} VIC
              </BalanceBox2>
            </div>
          </Row>
        </Box2>
      )}
      <WrongNetwork />
      <Box>
        {isConnected ? (
          <FullButton onClick={() => handleSubmit()}>
            Faucet Now
          </FullButton>
        ) : (
          <FullButton onClick={() => open()}>Connect Wallet</FullButton>
        )}

      </Box>
    </Main>
  );
}
