import styled from "styled-components";
import {deviceMax, lightTheme} from "../../themes";

export const ButtonConnect = styled.button`
  border-radius: 12px;
  background-color: ${lightTheme.greenColor};
  padding: 1rem 2rem;
  color: ${(props) => props.theme.MainColor};
  border-width: 0px;
  font-size: 16px;
  font-weight: 600;
`;
export const ButtonConnected = styled.button`
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 15px;
  color: ${(props) => props.theme.PrimaryColor2};
  border-width: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  @media (${deviceMax.mobileL}) {
    padding: 5px 5px;
  }
`;
export const Address = styled.span`
  border-radius: 1000px;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  color: ${lightTheme.greenColor};
  border-width: 0;
`;
export const Balance = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  color: ${lightTheme.greenColor};
  @media (max-width: 500px) {
    display: none;
  }
`;
