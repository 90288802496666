import { BiSolidFileExport } from "react-icons/bi"
import { GiTakeMyMoney } from "react-icons/gi"
import {Text} from "../../../pages/Explorer/styled"
import {formatEther, formatString} from "../../../utils/utils"
import React from "react"
import {SiMoneygram} from 'react-icons/si'
import {RiLuggageDepositFill} from 'react-icons/ri'
import {PiTreeStructureBold} from 'react-icons/pi'
import {FaBurn} from 'react-icons/fa'
import {BsFillPlusCircleFill} from 'react-icons/bs'

function Main(props: any): JSX.Element {
  const {main} = props
  const { action, vicAmount, sAmount } = main

  if (main === undefined) {
    return (<div></div>)
  }

  if (action === 'UnstakedAndBurn') {
    return (
        <div>
          <Text>
            <BiSolidFileExport style={{ marginRight: 8, fontSize: 22 }} />
            Unstake:{" "}{formatString(formatEther(vicAmount).toString())} VIC

            <FaBurn style={{ marginRight: 8, fontSize: 22, marginLeft: 10 }} />
            Burn {formatString(formatEther(sAmount).toString())}{" "} sVIC
          </Text>
        </div>
    );
  }
  if (action === "StakedAndMint") {
    return (
        <div>
          <Text>
            <RiLuggageDepositFill style={{ marginRight: 8, fontSize: 22 }} />
            Stake: {formatString(formatEther(vicAmount).toString())}{" "}VIC

            <BsFillPlusCircleFill style={{ marginRight: 8, fontSize: 22, marginLeft: 25 }} />
            Mint {formatString(formatEther(sAmount).toString())}{" "}sVIC
          </Text>
        </div>
    );
  }
  if (action === "Withdrawal") {
    return (
        <div>
          <Text>
            <GiTakeMyMoney style={{ marginRight: 8, fontSize: 22 }} />
            Withdrawal :{" "}
            {formatString(formatEther(vicAmount).toString())} VIC
          </Text>
        </div>
    );
  }
  if (action === "Unstake4Rebalance") {
    return (
        <div>
          <Text>
            <PiTreeStructureBold style={{ marginRight: 8, fontSize: 22 }}/>
            Unstake for Rebalance
          </Text>
        </div>
    );
  }
  if (action === "AccrueStake") {
    return (
        <div>
          <Text>
            <SiMoneygram style={{ marginRight: 8, fontSize: 22 }}/> Accrue Stake
          </Text>
        </div>
    );
  }
  return <div></div>;
}

export default Main
